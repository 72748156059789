/*  imported from index.less  */

#meepshop { .meepshop-facebook-wall__index__root {
  display: flex;
  width: 100%;

  &.meepshop-facebook-wall__index__FLEX_START {
    justify-content: flex-start;
  }

  &.meepshop-facebook-wall__index__CENTER {
    justify-content: center;
  }

  &.meepshop-facebook-wall__index__FLEX_END {
    justify-content: flex-end;
  }

  .fb-page {
    width: 100%;
    max-width: 500px;
  }
}
 }
@font-family: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";@font-family-no-number: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";